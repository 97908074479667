<template>
  <div
    class="container"
    @keyup.enter="login"
    :style="{ 'padding-top': statusBarHeight }"
  >
    <common-questions v-if="!loginStatus"></common-questions>
    <button class="register-btn" v-if="!loginStatus">註冊iMeddy會員</button>
    <button class="login-btn" @click="loginStatus = true" v-if="!loginStatus">
      登入
    </button>
    <el-input v-model="email" v-if="loginStatus" placeholder="電郵"></el-input>
    <el-input
      v-model="pwd"
      v-if="loginStatus"
      placeholder="密碼"
      type="password"
    ></el-input>
    <div v-if="loginStatus" class="button-group">
      <button @click="login">登录！</button>
      <span @click="loginStatus = false">返回</span>
      <span>忘记密码</span>
    </div>
  </div>
</template>

<script>
// import { isSafari } from "@/assets/js";
import { mapActions } from "vuex";
import commonQuestions from "../../components/commonQuestions.vue";
export default {
  components: { commonQuestions },
  data: function () {
    return {
      loginStatus: false,
      email: "",
      pwd: "",
      statusBarHeight: "0px",
    };
  },
  created() {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
    // let isSa = isSafari();
    // if (this.$store.state.uniappLogin) {
    window.uni.postMessage({
      data: {
        action: "update_page",
      },
    });
    // }
    // if (window.webBackToAndroid) {
    //   window.webBackToAndroid.onBack();
    // }
    // if (window.webkit) {
    //   window.webkit.messageHandlers.getDataFromWeb.postMessage("close");
    // }

    // if (isSa) {
    //   window.location.href = "iMeddy://com.imeddy.client?close=1";
    // }
  },
  methods: {
    ...mapActions({
      accountLogin: "user/login",
    }),
    login: function () {
      if (!this.loginStatus) return;
      let password;
      if (process.env.NODE_ENV != "production")
        password = this.$base64.encode(this.pwd);
      else password = this.$base64.encode(this.$md5(this.pwd));
      let data = {
        account: this.email,
        password,
      };
      this.accountLogin(data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "登錄成功",
          });
          this.$router.replace({ name: "index" });
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  padding: 16px;
  background-image: url("../../assets/img/common-questions-bg.png");
  background-size: cover;
  background-position: center;
  .login-btn,
  .register-btn {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
    width: 80%;
    margin: 16px auto;
  }
  .login-btn {
    background-color: white;
    color: $black;
  }
  .register-btn {
    background-color: $green-200;
    color: white;
    margin-top: 50vh;
  }
  .el-input::v-deep {
    box-sizing: border-box;
    width: 80%;
    margin: 0 10% 16px;
    .el-input__inner,
    .el-input__inner:hover,
    .el-input__inner:focus {
      text-align: center;
      background-color: transparent;
      border: 1px solid white;
      color: white;
    }
    .el-input__inner::placeholder {
      font-size: 16px;
      color: white;
      text-align: center;
    }
  }
  .el-input:first-of-type {
    margin-top: calc(50vh + 30px);
  }
  .button-group {
    margin: 0 10% 16px;
    @include flexr-between-center;
    span {
      color: white;
    }
  }
}
</style>